export const allRoutes = {
	HOME: "/",
	DASHBOARD: "/dashboard",
	ACCOUNT_SETTINGS: "/account-settings",
	UPDATE_STATUS: "/customers/update-status/:id",
	LOGS: "/customers/logs/:id",
	CUSTOMERS: "/customers",
	CHAT: "/chat",
	ACCEPT_SUPPORTCHAT_INVITE: "/accept-support-chat-invite",
	VIDEO_CHAT: "/chat/video-chat",
	VIEW_CUSTOMER: "/customers/view/:id",
	VIEW_CUSTOMER_DOCUMENTS: "/customers/documents/:id",
	ADD_CUSTOMER: "/customers/add-customer",
	EDIT_CUSTOMER: "/customers/edit/:id",
	INSTALLER_ADMINS: "/installer-admins",
	ADD_INSTALLER_ADMIN: "/installer-admins/add-installer-admin",
	VIEW_INSTALLER_ADMIN: "/installer-admins/view/:id",
	EDIT_INSTALLER_ADMIN: "/installer-admins/edit/:id",
	OFFICE_MANAGERS: "/office-managers",
	ADD_OFFICE_MANAGER: "/office-managers/add-office-manager",
	VIEW_OFFICE_MANAGER: "/office-managers/view/:id",
	VIEW_OFFICE_MANAGER_DASHBOARD: "/office-managers/view-dashboard/:id",

	EDIT_OFFICE_MANAGER: "/office-managers/edit/:id",
	MANAGERS: "/managers",
	ADD_MANAGER: "/managers/add-manager",
	VIEW_MANAGER: "/managers/view/:id",
	EDIT_MANAGER: "/managers/edit/:id",
	VIEW_MANAGER_DASHBOARD: "/managers/view-dashboard/:id",
	REPRESENTATIVES: "/representatives",
	ADD_REPRESENTATIVE: "/representatives/add-representative",
	VIEW_REPRESENTATIVE: "/representatives/view/:id",
	EDIT_REPRESENTATIVE: "/representatives/edit/:id",
	VIEW_REPRESENTATIVE_DASHBOARD: "/representatives/view-dashboard/:id",
	CUSTOMER_SUPPORT_CHAT: "/representatives/support-chat",
	TECHNICIANS: "/installation-crew",
	VIEW_TECHNICIAN: "/installation-crew/view/:id",
	CALENDAR_TECHNICIAN: "/installation-crew/calendar/:id",
	COMPANY_INFORMATION: "/company-information",

	EDIT_TECHNICIAN: "/installation-crew/edit/:id",
	ADD_TECHNICIAN: "/installation-crew/add-installation-crew",
	ESTIMATE: "/estimate",
	SOLAR_REPORT: "/solar-report",
	LOGIN: "/login",
	SIGN_UP: "/register",
	RESET_PASSWORD: "/reset-password/:userId?/:token?",
	VERIFY_EMAIL: "/auth/verify-email/:userId?/:token?",
	WHY_US: "/why-us",
	PROPOSAL_ACCEPTANCE: "/scheduling/proposal-acceptance",
	SITE_SURVEY: "/scheduling/site-survey",
	CAD_DESIGN: "/scheduling/cad-design",
	SCHEDULING: "/scheduling",
	PAYMENT: "/payment",
	REGISTER_TECHNICIAN: "/register-installation-crew/:token",
	ACCOUNT_CREATION: "/account-creation",
};
